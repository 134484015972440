//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Pagination from '~/components/Pagination.vue';
import Table from '~/components/ui/Table.vue';
import StatusModal from '~/components/modals/StatusModal.vue';
import TransactionScheme from '~/components/modals/TransactionScheme.vue';
import GetReport from '~/components/GetReport.vue';
import constant from '~/const';
import User from '../../components/User.vue';

import TransactionsHistoryFilters from './TransactionsHistoryFilters.vue';
import tableConfig from './transactionsHistoryTableConfig';

export default {
  components: {
    TransactionsHistoryFilters,
    Pagination,
    Table,
    StatusModal,
    TransactionScheme,
    GetReport,
    User,
  },

  data() {
    return {
      loader: false,
      filtersApplied: false,
      statusModal: {
        isOpen: false,
        operationId: 0,
        operationType: 0,
        kytStatus: 0,
      },
      showTransactionScheme: false,
      tableConfig,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('transactionsHistory', ['page', 'pages', 'data', 'sort', 'kytSettings']),

    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('transactionsHistory', ['requestData']),
    ...mapGetters('currencies', ['toCurrencyFormat']),

    statusModalData() {
      const { data } = this;
      const { operationId, operationType } = this.statusModal;
      return data.find((e) => (e.id === operationId && e.operationType === operationType));
    },

    reportOperations: () => constant.reports.REPORT_TYPE.OPERATIONS,
  },

  async mounted() {
    this.setGeneralLoader(true);
    if (this.$utils.getAppType() === 'p2pay') {
      await Promise.allSettled([
        this.getKytSettingsAction(),
        this.loadCurrencies(),
      ]);
    } else {
      await Promise.allSettled([
        this.loadCryptoCurrencies(),
        this.loadCryptoTokens(),
      ]);
    }
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'confirmAction',
    ]),
    ...mapActions('transactionsHistory', {
      getTransactionsHistoryAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      reloadSingleTransaction: 'reloadSingleTransaction',
      adminProcessingTransaction: 'adminProcessingTransaction',
      adminConfirmTransaction: 'adminConfirmTransaction',
      adminCompleteTransaction: 'adminCompleteTransaction',
      adminCancelTransaction: 'adminCancelTransaction',
      adminDeclineTransaction: 'adminDeclineTransaction',
      adminNeedContactTransaction: 'adminNeedContactTransaction',
      adminCheckTransaction: 'adminCheckTransaction',
      adminCancelCompletedDeposit: 'adminCancelCompletedDeposit',
      getTransactionDataAction: 'getTransactionData',
      getKytSettingsAction: 'getKytSettings',
    }),
    ...mapActions('paymentsAndCurrenciesPayments', {
      loadCryptoCurrencies: 'loadCryptoCurrencies',
      loadCryptoTokens: 'loadCryptoTokens',
    }),
    ...mapActions('user', {
      loadUsername: 'getUsername',
    }),
    ...mapActions('tradersCurrencies', {
      loadCurrencies: 'loadData',
    }),

    transferStatus(data) {
      this.statusModal.kytStatus = data;
    },

    isRiskValueMoreThanAllowed(type, risk) {
      const setting = this.kytSettings.find((e) => e.type === Number(type));
      const value = setting ? setting.value : 0;
      return value < risk;
    },

    percentConverter(value) {
      return `${parseFloat(Number(value * 100).toFixed(2))}%`;
    },

    getGeneralRiskType(risks) {
      return Object.keys(risks).reduce((a, b) => { return risks[a] > risks[b] ? a : b; });
    },

    riskColor(value) {
      const percent = value * 100;
      if (percent < 70) return 'success--text';
      if (percent >= 70 && percent <= 100) return 'warning--text';
      if (percent > 100) return 'error--text';
      return '';
    },

    async getTransactionsHistory(data = {}) {
      this.loader = true;
      this.setGeneralProgress(true);
      await this.getTransactionsHistoryAction(data);
      this.loader = false;
      this.setGeneralProgress(false);
    },

    async getUsername(id) {
      return this.loadUsername(id);
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
      }
      this.getTransactionsHistory();
      this.filtersApplied = true;
    },

    applySort(data) {
      this.setSortAction(data);
      this.getTransactionsHistory();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.getTransactionsHistory(extendedRequestData);
    },

    isStatusCanBeChanged(row) {
      const {
        AWAITING,
        ERROR,
        FROZEN,
      } = constant.traders.TRANSACTION_STATUS_CAPTION;

      const canBeChangedStatus = [
        AWAITING,
        ERROR,
        FROZEN,
      ];

      return canBeChangedStatus.includes(constant.traders.TRANSACTION_STATUS[row.status]);
    },

    openStatusModal(id, operationType) {
      this.statusModal.operationId = id;
      this.statusModal.operationType = operationType;

      const { status } = this.statusModalData;
      if (constant.traders.TRANSACTION_STATUS[status] === constant.traders.TRANSACTION_STATUS_CAPTION.AWAITING) {
        this.confirmAction({
          title: 'Attention!',
          text: 'This transaction is in the «Awaiting» status',
          callback: () => {
            this.statusModal.isOpen = true;
          },
        });
      } else {
        this.statusModal.isOpen = true;
      }
      this.getTransactionDataAction({ id });
    },

    async processing(data) {
      this.setGeneralProgress(true);
      await this.adminProcessingTransaction(data);
      // await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction is in processing');
    },

    async confirm(data) {
      this.setGeneralProgress(true);
      await this.adminConfirmTransaction(data);
      await this.getTransactionsHistoryAction();
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction confirmed');
    },

    async complete(data) {
      this.setGeneralProgress(true);
      await this.adminCompleteTransaction(data);
      await this.getTransactionsHistoryAction();
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction completed');
    },

    async approveKyt(data) {
      this.setGeneralProgress(true);
      await this.adminApproveKytTransaction(data);
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction approved');
    },

    async refuseKyt(data) {
      this.setGeneralProgress(true);
      await this.adminRefuseKytTransaction(data);
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction refused');
    },

    async cancel(data) {
      this.setGeneralProgress(true);
      await this.adminCancelTransaction(data);
      await this.getTransactionsHistoryAction();
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction canceled');
    },

    async decline(data) {
      this.setGeneralProgress(true);
      await this.adminDeclineTransaction(data);
      // await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction declined');
    },

    async needContact(data) {
      this.setGeneralProgress(true);
      await this.adminNeedContactTransaction(data);
      // await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction is in Need contact support status');
    },

    async check(data) {
      this.setGeneralProgress(true);
      await this.adminCheckTransaction(data);
      // await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction is in Admin check status');
    },

    async cancelCompleted(data) {
      this.setGeneralProgress(true);
      await this.adminCancelCompletedDeposit(data);
      // await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction canceled');
    },
  },
};
